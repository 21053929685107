import Vue from 'vue'
import App from './App.vue'

// 引入第三方依赖
import Element from 'element-ui'

// 引入自定义封装第三方插件
import router from './router'
import store from './store'

// 引入 js 自定义文件
import './error' // vue 错误日志
import './permission' //引入权限地址

//  引入样式
import 'element-ui/lib/theme-chalk/index.css' //element样式
import './styles/common.scss' //通用样式

import * as urls from './config'
import Cookies from 'js-cookie'
import Print from 'vue-print-nb'
// vue 插件使用
Vue.use(Element)
Vue.use(Print);
// 加载相关url地址
Object.keys(urls).forEach((key) => {
	Vue.prototype[key] = urls[key]
})

//引入自定义指令
Vue.use((Vue) => {
	const context = require.context('@/directives', false, /^\.\/.*\.js$/)
	context
		.keys()
		.map(context)
		.forEach((directive) => {
			directive =
				directive.__esModule && directive.default
					? directive.default
					: directive
			Object.keys(directive).forEach((key) => {
				Vue.directive(key, directive[key])
			})
		})
})

Vue.prototype.$Cookies = Cookies
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
