import Layout from '@/pages/index/'
export default [
	{
		path: '/home',
		redirect: '/home/index',
		component: Layout,
		children: [
			{
				path: 'index',
				name: '首页',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/home/index.vue'
					),
			},
		],
	},
	{
		path: '/statistics',
		redirect: '/statistics/receive',
		component: Layout,
		children: [
			{
				path: 'receive',
				name: '收件统计',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/statistics/receive.vue'
					),
			},
			{
				path: 'delivery',
				name: '派件统计',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/statistics/delivery.vue'
					),
			},
			{
				path: 'wallet',
				name: '钱包统计',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/statistics/wallet.vue'
					),
			},
			{
				path: 'shop',
				name: '商户统计',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/statistics/shop.vue'
					),
			},
		],
	},
	{
		path: '/user',
		redirect: '/user/user',
		component: Layout,
		children: [
			{
				path: 'user',
				name: '用户管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/user/user.vue'
					),
			},
			{
				path: 'certification',
				name: '实名认证管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/user/certification.vue'
					),
			},
			{
				path: 'complaint',
				name: '用户投诉管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/user/complaint.vue'
					),
			},
		],
	},
	{
		path: '/advertisement',
		redirect: '/advertisement/banner',
		component: Layout,
		children: [
			{
				path: 'banner',
				name: '首页轮播图管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/advertisement/banner.vue'
					),
			},
			{
				path: 'walletBanner',
				name: '钱包轮播图管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/advertisement/walletBanner.vue'
					),
			},
			{
				path: 'advertisement',
				name: '广告管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/advertisement/advertisement.vue'
					),
			},
			{
				path: 'topic',
				name: '专题管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/advertisement/topic.vue'
					),
			},
		],
	},
	{
		path: '/market',
		redirect: '/market/market',
		component: Layout,
		children: [
			{
				path: 'market',
				name: '市场管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/market/market.vue'
					),
			},
			{
				path: 'floor',
				name: '楼层管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/market/floor.vue'
					),
			},
			{
				path: 'booth',
				name: '档口号管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/market/booth.vue'
					),
			},
		],
	},
	{
		path: '/express',
		redirect: '/express/expressCompany',
		component: Layout,
		children: [
			{
				path: 'expressCompany',
				name: '快递公司管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/express/expressCompany.vue'
					),
			},
			{
				path: 'transportCompany',
				name: '货运公司管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/express/transportCompany.vue'
					),
			},
			{
				path: 'freightTemplate',
				name: '运费模板管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/express/freightTemplate.vue'
					),
			},
			{
				path: 'itemType',
				name: '物品类型管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/express/itemType.vue'
					),
			},
			{
				path: 'shippingPoint',
				name: '托运点管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/express/shippingPoint.vue'
					),
			},
		],
	},
	{
		path: '/shop',
		redirect: '/shop/shop',
		component: Layout,
		children: [
			{
				path: 'shop',
				name: '商户管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/shop/shop.vue'
					),
			},
			{
				path: 'enter',
				name: '入驻管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/shop/enter.vue'
					),
			},
			{
				path: 'expressNumber',
				name: '快递单号管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/shop/expressNumber.vue'
					),
			},
		],
	},
	{
		path: '/courier',
		redirect: '/courier/express',
		component: Layout,
		children: [
			{
				path: 'express',
				name: '快递收派员管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/courier/express.vue'
					),
			},
			{
				path: 'local',
				name: '同城收派员管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/courier/local.vue'
					),
			},
		],
	},
	{
		path: '/order',
		redirect: '/order/storage',
		component: Layout,
		children: [
			{
				path: 'storage',
				name: '入库管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/order/storage.vue'
					),
			},
			{
				path: 'delivery',
				name: '派件管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/order/delivery.vue'
					),
			},
			{
				path: 'receive',
				name: '收件管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/order/receive.vue'
					),
			},
		],
	},
	{
		path: '/finance',
		redirect: '/finance/checking',
		component: Layout,
		children: [
			{
				path: 'checking',
				name: '对账管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/finance/checking.vue'
					),
			},
			{
				path: 'coupon',
				name: '优惠券管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/finance/coupon.vue'
					),
			},
			{
				path: 'wallet',
				name: '钱包管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/finance/wallet.vue'
					),
			},
		],
	},
	{
		path: '/admin',
		redirect: '/admin/role',
		component: Layout,
		children: [
			{
				path: 'role',
				name: '角色管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/admin/role.vue'
					),
			},
			{
				path: 'admin',
				name: '管理员管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/admin/admin.vue'
					),
			},
		],
	},
	{
		path: '/system',
		redirect: '/system/menu',
		component: Layout,
		children: [
			{
				path: 'menu',
				name: '菜单管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/menu.vue'
					),
			},
			{
				path: 'address',
				name: '省级信息管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/address.vue'
					),
			},
			{
				path: 'city',
				name: '市级信息管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/city.vue'
					),
			},
			{
				path: 'area',
				name: '区级信息管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/area.vue'
					),
			},
			{
				path: 'abnormalFeedback',
				name: '功能异常反馈管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/abnormalFeedback.vue'
					),
			},
			{
				path: 'parameter',
				name: '参数设置',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/parameter.vue'
					),
			},
			{
				path: 'operationLog',
				name: '操作日志管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/operationLog.vue'
					),
			},
			{
				path: 'helpCenter',
				name: '帮助中心管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/helpCenter.vue'
					),
			},
			{
				path: 'agreement',
				name: '协议管理',
				component: () =>
					import(
						/* webpackChunkName: "views" */ '@/views/system/agreement.vue'
					),
			},
		],
	},
]
