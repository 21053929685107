import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

/**
 * 
 * @param {*} type 
 * @link https://day.js.org/docs/en/manipulate/start-of
 * @returns 
 */
export function getTimeRangeByType(type) {
  let offestDay = type === "week" ? 1 : 0;
  let start_time = dayjs()
    .startOf(type)
    .add(offestDay, "day")
    .format("YYYY-MM-DD");
  let end_time = dayjs()
    .endOf(type)
    .add(offestDay, "day")
    .format("YYYY-MM-DD");
  return [start_time, end_time];
}
