// const baseUrl = 'http://192.168.1.99:7903' //请求地址

const baseUrl = 'https://dbk.0577dxc.com/' //请求地址

const statusWhiteList = [] //http的status默认放行不才用统一处理的,

const uploadImgApi = baseUrl + '/api/market/common/uploadImage' //图片上传地址

const excelToStore = baseUrl + '/api/market/order/excelToStore' //excel上传地址

export { baseUrl, statusWhiteList, uploadImgApi, excelToStore }
