<template>
	<div class="header">
		<el-tabs v-model="tabsValue" type="card" class="tags">
			<el-tab-pane
				v-for="(item, index) in tagList"
				:key="item.value"
				:label="item.label"
				:name="item.value"
			>
				<div
					slot="label"
					class="tags-item"
					:class="checkIsActive(item)"
					@click="handleClickTag(item)"
				>
					<img
						class="home-icon"
						v-if="item.label === '首页'"
						:src="
							checkIsActive(item)
								? require('@/assets/img/home_title_icon_active.png')
								: require('@/assets/img/home_title_icon.png')
						"
					/>
					<span>{{ item.label }}</span>
					<img
						v-if="checkIsDel(item)"
						class="del-btn"
						src="@/assets/img/close_icon.png"
						@click.stop="handleDelTag(item, index)"
					/>
				</div>
			</el-tab-pane>
		</el-tabs>
		<div class="close-btn-container">
			<el-popover
				placement="bottom"
				trigger="click"
				popper-class="close-btn-pop"
				v-model="closeBtnVisible"
			>
				<div class="close-options">
					<div
						class="close-option-item"
						v-for="item in closeOptions"
						:key="item.value"
						@click="clickCloseOptionItem(item)"
					>
						<span>{{ item.label }}</span>
					</div>
				</div>
				<div class="close-btn" slot="reference">
					<img src="../../assets/img/del_btn_solid.png" />
				</div>
			</el-popover>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			tabsValue: '',
			closeOptions: [
				{
					label: '关闭所有',
					value: 1,
				},
				{
					label: '关闭其他',
					value: 2,
				},
			],
			closeBtnVisible: false,
		}
	},
	computed: {
		...mapGetters(['tagList', 'tag']),
	},
	watch: {
		$route(route) {
			//监听路由复制给tabs 进行滚动
			const { path } = route
			this.tabsValue = path
		},
	},
	methods: {
		clickCloseOptionItem(item) {
			if (item.value === 1) {
				//关闭所有
				this.$store.commit('DEL_ALL_TAG')
				const homePath = '/home/index'
				if (this.$route.path !== homePath) {
					this.$router.push({
						path: homePath,
					})
				}
			} else if (item.value === 2) {
				//关闭其他
				this.$store.commit('DEL_TAG_OTHER')
			}
			this.closeBtnVisible = false
		},
		handleDelTag(tag, index) {
			let key = index
			this.$store.commit('DEL_TAG', tag)
			if (tag.value === this.tag.value) {
				tag = this.tagList[key === 0 ? key : key - 1] //如果关闭本标签让前推一个
				this.openTag(tag)
			}
		},
		openTag(item) {
			//打开tag标签栏
			let tag
			if (item.name) {
				tag = this.findTag(item.name).tag
			} else {
				tag = item
			}
			this.$router.push({
				path: tag.value,
				query: tag.query,
			})
		},
		findTag(value) {
			//寻找相应的tag标签栏
			let tag, key
			this.tagList.map((item, index) => {
				if (item.value === value) {
					tag = item
					key = index
				}
			})
			return { tag: tag, key: key }
		},
		handleClickTag(tag) {
			let path = this.$route.path || ''
			let value = tag.value || ''
			value && path.indexOf(value) === -1 && this.$router.push(tag.value)
		},
		checkIsActive(tag) {
			//判断tag 路径与路由是否相同
			let path = this.$route.path || ''
			return path.indexOf(tag.value || '') !== -1 ? 'active' : ''
		},
		checkIsDel(tag) {
			//判断tag 路径与路由是否相同 并且不等于首页
			let path = this.$route.path || ''
			return path.indexOf(tag.value || '') !== -1 && tag.label !== '首页'
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.header {
	width: 100%;
	height: 80px;
	padding: 20px 50px 20px 30px;
	flex-shrink: 0;
	border-bottom: 1px solid #eeeeee;
	position: relative;

	/deep/ .el-tabs__header {
		border: unset;
		margin: unset;
		.el-tabs__item {
			border: unset;
			outline: unset;
			background: unset;
			padding: unset;
			margin: unset;
			height: unset;
			line-height: unset;
			&:nth-child(2) {
				padding-left: 0 !important;
			}
		}
		.el-tabs__nav-prev {
			line-height: 36px;
		}
		.el-tabs__nav-next {
			line-height: 36px;
		}
		.el-tabs__nav {
			border: unset;
			display: flex;
			align-items: center;
		}
	}
}
.tags {
	.tags-item {
		height: 36px;
		padding: 7px 15px;
		border-radius: 10px;
		margin-right: 15px;
		border: 1px solid #eeeeee;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		img {
			display: block;
			margin-top: 2px;
		}
		&.active {
			border-color: $--color-primary;
			span {
				color: $--color-primary;
			}
		}
		span {
			font-size: 16px;
			color: #333333;
		}
		.del-btn {
			width: 12px;
			height: auto;
			margin-left: 10px;
		}
		.home-icon {
			width: 18px;
			height: 18px;
			margin-right: 8px;
		}
	}
}

.close-btn-container {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	.close-btn {
		padding: 5px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 18px;
			height: auto;
		}
	}
}

.close-btn-pop {
	.close-options {
		.close-option-item {
			padding: 10px 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
}
</style>
