export default {
    permission: {
        inserted(el, binding) {
            const { value } = binding
            const jurisdictionList = JSON.parse(sessionStorage.jurisdictionList)
            if (!jurisdictionList.includes(value)) {
                el.parentNode.removeChild(el);
            }
        }
    }
}