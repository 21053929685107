<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {},
}
</script>

<style lang="scss">
#app {
	width: 100%;
	height: 100vh;
}
.text-overflow {
	white-space: nowrap;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pop-menu {
	min-width: unset !important;
}

.close-btn-pop {
	min-width: unset !important;
	padding: 5px 0 !important;
}
</style>
