const trigger = (el, type) => {
	const e = document.createEvent('HTMLEvents')
	e.initEvent(type, true, true)
	el.dispatchEvent(e)
}

export default {
	'el-input-number': {
		/*
      使用说明：
        v-el-input-number="{ 
          length:0,  //输入数字的最大长度
          min: 0, //输入数字的最小值
          max:0, //输入数字的最大值
        }"
    */
		bind(el, binding) {
			const { value: bindingValue = {} } = binding
			const { length, max, min } = bindingValue
			const input = el.getElementsByTagName('input')[0]
			input.oninput = (event) => {
				let value = event.target.value
				if (length !== undefined) {
					value = value.slice(0, length)
				}
				if (max !== undefined) {
					value = value >= max ? max : value
				}
				if (min !== undefined) {
					value = value <= min ? min : value
				}
				input.value = !isNaN(value) ? value : ''
			}
			input.onblur = () => {
				trigger(input, 'input') //为了防止 v-model 获取到的值还是为旧值
			}
			input.onkeypress = (event) => {
				let reg = /\d|\+|-|\./ //仅允许键盘点击  数字 正负号 小数点
				return (
					reg.test(
						String.fromCharCode(event.keyCode || event.which)
					) || event.which === 8
				)
			}
		},
	},
}
