export default [
	{
		path: '/',
		name: '主页',
		redirect: '/home',
	},
	{
		path: '/login',
		name: '登录',
		meta: { isTab: false },
		component: () =>
			import(/* webpackChunkName: "pages" */ '@/pages/login/index.vue'),
	},
	{
		path: '/topicDetail',
		name: '专题详情',
		component: () =>
			import(/* webpackChunkName: "pages" */ '@/pages/topic/detail.vue'),
	},
	{
		path: '/404',
		component: () =>
			import(
				/* webpackChunkName: "page" */ '@/components/error-page/404'
			),
		name: '404',
		meta: {
			keepAlive: true,
			isTab: false,
			isAuth: false,
		},
	},
	{
		path: '/403',
		component: () =>
			import(
				/* webpackChunkName: "page" */ '@/components/error-page/403'
			),
		name: '403',
		meta: {
			keepAlive: true,
			isTab: false,
			isAuth: false,
		},
	},
	{
		path: '/500',
		component: () =>
			import(
				/* webpackChunkName: "page" */ '@/components/error-page/500'
			),
		name: '500',
		meta: {
			keepAlive: true,
			isTab: false,
			isAuth: false,
		},
	},
	{
		path: '*',
		redirect: '/404',
	},
]
