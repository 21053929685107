<template>
	<el-container id="index">
		<el-aside id="aside" width="210px">
			<div class="logo">
				<img src="@/assets/img/header_logo.png" alt="logo" />
			</div>
			<div class="nav-menu">
				<el-menu
					class="el-menu-vertical"
					background-color="#242632"
					text-color="#FFFFFF"
					:default-active="menuDefaultActive"
					:default-openeds="menuDefaultOpeneds"
					:unique-opened="true"
					:router="true"
				>
					<el-submenu
						:class="[checkIsMenuActive(item)]"
						v-for="item in menuList"
						:key="item.path"
						:index="item.path"
					>
						<div slot="title">
							<img
								:src="item.icon"
								@error="onerror($event)"
								style="width:22px;height:22px;margin-right:10px;"
							/>
							<!-- <i class="el-icon-location"></i>- -->
							<span>{{ item.name }}</span>
						</div>
						<template
							v-if="item.childMenu && item.childMenu.length"
						>
							<el-menu-item
								v-for="menuItem in item.childMenu"
								:key="menuItem.id"
								:path="menuItem.path"
								:index="menuItem.path"
								:class="
									checkIsMenuItemActive(menuItem)
										? 'active'
										: ''
								"
							>
								<img
									v-if="checkIsMenuItemActive(menuItem)"
									:src="menuItem.icon"
									@error="onerror($event)"
								/>
								<span>{{ menuItem.name }}</span>
							</el-menu-item>
						</template>
					</el-submenu>
				</el-menu>
			</div>
		</el-aside>
		<el-container class="main-container">
			<el-header class="top-header" height="80px">
				<div class="header-nav">
					<el-breadcrumb>
						<el-breadcrumb-item>首页</el-breadcrumb-item>
					</el-breadcrumb>
					<el-breadcrumb
						v-for="(item, index) in menuNameList"
						:key="index"
					>
						<el-breadcrumb-item>{{ item }}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<div class="header-info">
					<div class="userinfo">
						<img
							class="avatar"
							src="@/assets/img/default_avatar.png"
							alt="用户头像"
						/>
						<span class="username">{{ account }}</span>
					</div>
					<div class="logout-btn" @click="handleLogout">
						<img
							class="icon"
							src="@/assets/img/logout_icon.png"
							alt="退出登录icon"
						/>
						<span>退出登录</span>
					</div>
				</div>
			</el-header>
			<el-main class="view-container">
				<div class="view-content">
					<header-line>
						<div class="tabs">
							<div class="tabs-item">菜单管理</div>
						</div>
					</header-line>
					<router-view></router-view>
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>
<script>
import HeaderLine from '@/components/baseUI/HeaderLine'
// import imgs from '@/assets/img/menu_item_icon'
export default {
	components: {
		HeaderLine,
	},
	data() {
		return {
			menuList: [],
			account: '',
		}
	},
	computed: {
		menuDefaultOpeneds() {
			//当前打开的 sub-menu 的 index 的数组
			return [this.$route.matched[0].path]
		},
		menuDefaultActive() {
			//菜单栏的默认选中项
			return this.$route.path || '/home/index'
		},
		menuNameList() {
			const getMenuName = (menuList = [], menu = {}) => {
				if (menuList && menuList.length) {
					for (let i = 0; i < menuList.length; i++) {
						const item = menuList[i]
						if (item.path === menu.path) {
							menuNameList.push(item.name)
							return
						} else {
							if (item.childMenu) {
								getMenuName(item.childMenu, menu)
							} else {
								return
							}
						}
					}
				} else {
					return
				}
			}
			const { matched = [] } = this.$route
			let menuNameList = []
			if (matched[0].path !== '/home') {  //路由不为首页时
				matched.forEach((item) => {
					getMenuName(this.menuList, item)
				})
			} else {
        // 当路由为首页之展示首页
			}
			return menuNameList
		},
	},
	created() {
		let menu = JSON.parse(sessionStorage.getItem('menu'))
		this.menuList = menu
		this.account = sessionStorage.getItem('account')
	},
	methods: {
		// 处理退出登录事件
		handleLogout() {
			this.$confirm('是否退出登录？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					sessionStorage.clear()
					this.$message.success('退出登录成功')
					this.$router.replace('/login')
				})
				.catch(() => {
					this.$message.info('操作取消')
				})
		},
		checkIsMenuActive(data) {
			//判断menu是否active  根据路由中的path判断
			const menuPath = data.path || ''
			const path = this.$route.path
			return path.indexOf(menuPath) === 0 ? 'menu-active' : ''
		},
		checkIsMenuItemActive(data) {
			//判断menu是否active  根据路由中的path判断
			const menuPath = data.path || ''
			const path = this.$route.path
			return path.indexOf(menuPath) !== -1 ? true : false
		},
		onerror(ev){
			ev.target.src = require('../../assets/img/三角形 2@2x.png')
			ev.srcElement.onerror = null
			console.log(ev,'asfdsdf')
		}
	},
}
</script>
<style lang="scss" scoped>
#index {
	width: 100%;
	height: 100%;
}

#aside {
	background-color: #242632;

	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 80px;

		img {
			width: 100px;
			height: auto;
		}
	}
}

.view-container {
	padding: 28px;
	background-color: #f5f5f7;

	.view-content {
		width: 100%;
		min-height: 100%;
		background-color: #ffffff;
		border-radius: 10px;
		display: flex;
		flex-flow: column wrap;
	}
}

.top-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 30px;
}

.header-info {
	display: flex;
	align-items: center;

	.userinfo {
		display: flex;
		align-items: center;
		margin-right: 50px;

		.avatar {
			width: 36px;
			height: 36px;
			object-fit: contain;
			margin-right: 10px;
		}
		.username {
			font-style: 16px;
		}
	}

	.logout-btn {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-right: 5px;

		.icon {
			width: 16px;
			height: 16px;
			object-fit: contain;
			margin: 1px 5px 0 0;
		}

		span {
			font-size: 14px;
		}
	}
}
/* 重置 header 中的面包屑 样式 */
/deep/ .el-breadcrumb__item {
	.el-breadcrumb__inner {
		font-size: 18px;
	}
}

/* 重置 element 导航栏样式 */

/deep/ .el-menu-vertical {
	.menu-active {
		& > .el-submenu__title {
			background-color: #f53131 !important;
		}
	}

	.el-menu-item {
		background-color: #000000;
		display: flex;
		align-items: center;
		padding-left: 77px !important;
		position: relative;

		&.active {
			span {
				color: #ffffff;
			}
		}

		img {
			width: 9px;
			height: auto;
			margin-right: 8px;
			position: absolute;
			left: 60px;
			top: 50%;
			transform: translateY(-50%);
		}

		span {
			color: #8d8e93;
		}
	}
}
.main-container {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	overflow: auto;
}
::-webkit-scrollbar {
	width: 4px;
	/*height: 4px;*/
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 0;
	background: rgba(0, 0, 0, 0.1);
}

.header-nav {
	display: flex;
	flex-flow: row wrap;
	/deep/ .el-breadcrumb {
		display: flex;
		align-items: center;
		span {
			color: #999999 !important;
		}
		&:last-of-type {
			span {
				color: #333 !important;
			}
			&::after {
				display: none;
			}
		}
		&::after {
			content: '/';
			font-size: 18px;
			margin: 0 15px;
			color: #999999;
		}
	}
}
</style>
